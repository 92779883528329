import { list } from 'forest';
import { NodeList, Node, NodeTitle } from './components';
export function Events($events) {
    NodeList(() => {
        const $list = $events.map((map) => Object.entries(map).map(([name, meta]) => ({ name, ...meta })));
        list({
            source: $list,
            key: 'name',
            fields: ['name', 'lastTriggeredWith'],
            fn({ fields: [$name] }) {
                Node(() => {
                    NodeTitle({ text: [$name, ' '] });
                    // NodeContent(() => {
                    //   h('span', { text: 'with: ' });
                    //   ObjectView({ value: $lastTriggeredParams });
                    // });
                    // NodeButton({
                    //   text: 'Logs',
                    //   handler: { click: changeTab.prepend(() => 'logs') },
                    // });
                });
            },
        });
    });
}
