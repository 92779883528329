import { createEvent, restore } from 'effector';
import { Section, SectionHead, SectionTab, SectionContent } from './components';
import { createSetting } from './setting';
const lastTab = createSetting('last-tab');
export function Tabs(tabs) {
    const tabList = Object.keys(tabs);
    const firstTab = tabList[0];
    const savedTab = lastTab.read(firstTab);
    const initialTab = tabList.includes(savedTab) ? savedTab : firstTab;
    const changeTab = createEvent();
    const $tab = restore(changeTab, initialTab);
    $tab.watch(lastTab.write);
    Section(() => {
        SectionHead(() => {
            for (const key of tabList) {
                const tab = tabs[key];
                SectionTab({
                    text: tab.title,
                    data: {
                        active: $tab.map((current) => current === key),
                    },
                    handler: { click: changeTab.prepend(() => key) },
                });
            }
        });
        for (const key of tabList) {
            const tab = tabs[key];
            SectionContent({
                visible: $tab.map((current) => current === key),
                fn() {
                    tab.fn({ changeTab });
                },
            });
        }
    });
}
