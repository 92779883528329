const PREFIX = 0xeffec ** 3;
function read(name, defaultValue) {
    return localStorage.getItem(`${PREFIX}-${name}`) ?? defaultValue ?? '';
}
function write(name, value) {
    localStorage.setItem(`${PREFIX}-${name}`, value);
    return value;
}
export function createSetting(name) {
    return {
        read: (defaultValue) => read(name, defaultValue),
        write: (value) => write(name, value),
    };
}
export function createJsonSetting(name) {
    return {
        read: (defaultValue) => JSON.parse(read(name, JSON.stringify(defaultValue))),
        write: (value) => {
            write(name, JSON.stringify(value));
            return value;
        },
    };
}
